import React from "react"
import Page from "../components/page.js"

export default () => {
  return (
    <Page>
      <div>At the New York Times:</div>
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/11/02/us/politics/trump-places-quotes.html?unlocked_article_code=1.bU4.C4ZY.2yTAnmjXMl1S&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          ‘Cesspools,’ ‘Hellholes’ and ‘Beautiful Places’: How Trump Describes the U.S.
          </a>{" "}
          (2024)
        </div>
            
        <div>
        + <a
          href="https://www.nytimes.com/article/israel-lebanon-invasion-map.html?unlocked_article_code=1.bU4.luqp.f2o4BwBgvjYK&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          Mapping Israel’s Invasion of Lebanon
          </a>{" "}
          (2024)
        </div>
            
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/09/29/us/helene-path-map.html?unlocked_article_code=1.bU4.79_n.Aure1UHSBDSR&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          Hurricane Helene: Mapping More Than 600 Miles of Devastation
          </a>{" "}
          (2024)
        </div>

        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/09/07/us/politics/harris-trump-rally-crowds-size.html?unlocked_article_code=1.bU4.T7GZ.Ejf9fFx60HDg&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          Trump Claims Harris’s Rallies Are Smaller. We Counted.
          </a>{" "}
          (2024)
        </div>
            
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/07/16/world/middleeast/israel-hostage-rescue-nuseirat.html?unlocked_article_code=1.bU4.mkx2.ut6UeGhQUL_-&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          How the Israeli Hostage Rescue Led to One of Gaza’s Deadliest Days
          </a>{" "}
          (2024)
        </div>

        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/05/28/arts/gaza-omari-mosque.html?unlocked_article_code=1.bU4.crNU.PBITe7X7Qbh1&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          Gaza’s Historic Heart, Now in Ruins
          </a>{" "}
          (2024)
        </div>
            
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/05/15/world/middleeast/israel-gaza-rafah-map.html?unlocked_article_code=1.bU4.QIhu.NPp8jB_MLbUa&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          Rafah Operation Pushes Gazans Into Areas With Scarce Aid and Medical Care
          </a>{" "}
          (2024)
        </div>
            
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/05/03/us/ucla-protests-encampment-violence.html?unlocked_article_code=1.pU0.KrbY.uNidGQ5iXlaj&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          How Counterprotesters at U.C.L.A. Provoked Violence, Unchecked for Hours
          </a>{" "}
          (2024)
        </div>
    
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/02/01/world/middleeast/Israel-gaza-war-demolish.html?unlocked_article_code=1.aU0.RhFf.6_-ZiY4QDvtV&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          Israel’s Controlled Demolitions Are Razing Neighborhoods in Gaza
          </a>{" "}
          (2024)
        </div>
    
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2023/12/28/world/middleeast/jabaliya-gaza-strike-israel.html?unlocked_article_code=1.aU0.D27k.BNmnmYvYh6Dr&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          What Happened in One of Israel's Deadliest Attacks in Gaza
          </a>{" "}
          (2023)
        </div>
      
      <div>At SITU Research:</div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/sow-et-al-v-city-of-new-york-et-al"
          target="_blank"
          rel="noreferrer"
        >
          Sow, et al. v. City of New York, et al.
        </a>{" "}
        (2023)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/death-at-the-station-russian-cluster-munition-attack-in-kramatorsk-ukraine"
          target="_blank"
          rel="noreferrer"
        >
          Death at the Station
        </a>{" "}
        (2023)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/crime-scene-bucha"
          target="_blank"
          rel="noreferrer"
        >
          Crime Scene: Bucha
        </a>{" "}
        (2022)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/codec-collaborative-tool"
          target="_blank"
          rel="noreferrer"
        >
          Codec: a collaborative event reconstruction tool
        </a>{" "}
        (2022)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/operation-siloe-event-reconstruction"
          target="_blank"
          rel="noreferrer"
        >
          Operación Siloé Event Reconstuction
        </a>{" "}
        (2022)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/after-the-strike"
          target="_blank"
          rel="noreferrer"
        >
          After the Strike
        </a>{" "}
        (2021)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/charlotte-kettling-analysis"
          target="_blank"
          rel="noreferrer"
        >
          Charlotte Kettling Analysis
        </a>{" "}
        (2021)
      </div>

      <div>
        + <a
          href="https://situ.nyc/news/unitad-video-release-yazidi-iraq"
          target="_blank"
          rel="noreferrer"
        >
          UNITAD
        </a>{" "}
        (2021)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/portland-protest-reconstruction"
          target="_blank"
          rel="noreferrer"
        >
          Portland June 30th Protest
        </a>{" "}
        (2020)
      </div>
      <div>
        + <a
          href="https://situ.nyc/research/projects/mott-haven-protest"
          target="_blank"
          rel="noreferrer"
        >
          The Trap
        </a>{" "}
        (2020)
      </div>
      <div>
        + <a
          href="https://situ.nyc/research/projects/tear-gas"
          target="_blank"
          rel="noreferrer"
        >
          Choking Dissent
        </a>{" "}
        (2020)
      </div>
      <div>
        + <a
          href="https://situ.nyc/research/projects/smokescreen-iraqs-use-of-militarygrade-tear-gas-grenades-to-kill-protesters"
          target="_blank"
          rel="noreferrer"
        >
          Smokescreen
        </a>{" "}
        (2020)
      </div>

      <div>
        + <a
          href="http://gieinicaragua-cartografia-violencia.org"
          target="_blank"
          rel="noreferrer"
        >
          Nicaragua: Mapping Violence in the Context of Protests
        </a>{" "}
        (2019)
      </div>

      <div>
        + <a
          href="https://theintercept.com/2023/06/12/icc-war-crimes-digital-evidence-reconstruction"
          target="_blank"
          rel="noreferrer"
        >
          ICC Al Hassan Case
        </a>{" "}
        (2019)
      </div>

      <div>Juliana vs US (2019)</div>
    </Page>
  )
}
